<template>
    <div 
        id ="container-form-meli" 
        class="d-flex p-4 flex-column align-items-center bg-white animated fadeInDown justify-content-start">
        <div class="d-flex align-items-center container-img">
            <img src="https://storage.googleapis.com/marketcenter-labels-local/logo_enviame.svg" width="200"  alt="">
            <hr class="line-orientation">
            <img src="https://storage.googleapis.com/marketcenter-labels/logo_meli.png" width="200"  alt="">
        </div>
        <div class="d-flex flex-column align-items-center mt-2">
            <text-success-integration-meli 
                :title="title" 
                :class="{'animated fadeInDown' : isIntegration}">
                <template v-slot>
                    <div>
                        <p 
                            class="mt-50 text-center" 
                            :class="{'mb-0' : isIntegration}"
                        >
                            <span v-if="initialForm">Ya estás a pocos pasos de tener todo listo para usar tu cuenta Envíame.</span>
                            <span v-if="successIntegration">Hemos enviado tus <strong>credenciales de acceso temporales al correo</strong> del usuario que acabas de confirmar.</span>
                        </p>
                        <p 
                            class="text-center" 
                            :class="{'m-0' : isIntegration}"
                        >
                            <span v-if="initialForm">Confirma los siguientes datos y crea una contraseña segura. Para continuar selecciona el botón <strong>“Confirmar”</strong>.</span>
                            <span v-if="successIntegration">Por tu seguridad, recomendamos que cambies tu contraseña luego del primer acceso.</span>
                        </p>
                        <p 
                            class="text-center m-0 mt-2" 
                            v-if="isIntegration"
                        >
                            <span v-if="successIntegration">¡Te esperamos pronto en Envíame!</span>
                        </p>
                    </div>
                </template>
            </text-success-integration-meli>
            <div class="wd-form-meli" v-if="initialForm">
                <validation-observer
                    ref="meliForm"
                    #default="{invalid}"
                >
                    <b-form class="meli-form mt-1" @submit.prevent="validateForm">
                        <!-- company -->
                        <b-form-group class="mt-1">
                            <div class="d-flex justify-content-between">
                                <label for="company">
                                    <strong>{{$t('Empresa')}}&nbsp;</strong>
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <validation-provider #default="{ errors }" name="company" rules="required">
                                <b-form-input
                                    id="company"
                                    v-model="userCompany"
                                    disabled
                                    name="meli-company"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Ingresa tu empresa"
                                />
                                <small class="text-danger d-flex justify-content-start ml-50">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- name -->
                        <b-form-group class="mt-1">
                            <div class="d-flex justify-content-between">
                                <label for="name">
                                    <strong>{{$t('Nombre de usuario')}}&nbsp;</strong>
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <validation-provider #default="{ errors }" name="name" rules="required">
                                <b-form-input
                                    id="name"
                                    v-model="userName"
                                    :disabled="loading"
                                    name="meli-name"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Ingresa tu nombre de usuario"
                                />
                                <small class="text-danger d-flex justify-content-start ml-50">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- last-name -->
                        <b-form-group class="mt-1">
                            <div class="d-flex justify-content-between">
                                <label for="name">
                                    <strong>{{$t('Apellido de usuario')}}&nbsp;</strong>
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <validation-provider #default="{ errors }" name="lastName" rules="required">
                                <b-form-input
                                    id="lastName"
                                    v-model="userLastName"
                                    :disabled="loading"
                                    name="meli-last-name"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Ingresa tu apellido de usuario"
                                />
                                <small class="text-danger d-flex justify-content-start ml-50">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- email -->
                        <b-form-group class="mt-1">
                            <div class="d-flex justify-content-between">
                                <label for="email">
                                    <strong>{{$t('Usuario')}}&nbsp;</strong>
                                    <span class="text-danger">*</span>
                                </label>
                            </div>
                            <validation-provider #default="{ errors }" name="email" rules="required|email">
                                <b-form-input
                                    id="email"
                                    v-model="userEmail"
                                    :disabled="loading"
                                    name="meli-email"
                                    :state="errors.length > 0 ? false:null"
                                    :class="{'is-valid': !existingEmail && !!currentEmail}"
                                    :placeholder="!!existingEmail ? `Ingrese correo diferente a ${currentEmail}` : 'Ingresa tu usuario'"
                                    @keyup="onChangeEmail"
                                />
                                <small v-if="!!existingEmail" class="text-danger d-flex justify-content-start ml-50">Ya existe una cuenta Envíame asociada al correo {{currentEmail}}</small>
                                <small v-else class="text-danger d-flex justify-content-start ml-50">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <div class="d-flex justify-content-center">
                            <b-button class="mt-4 custom-btn-form-meli wd-btn-meli" type="submit" block :disabled="invalid || isDisabled">
                                <feather-icon v-if="loading" icon="LoaderIcon" class="spinner"/>
                                {{$t('Confirmar')}}
                            </b-button>
                        </div> 
                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup
} from 'bootstrap-vue'
import { required, email } from '@validations'
import BaseServices from '@/store/services/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TextSuccessIntegrationMeli from './components/TextSuccessIntegrationMeli.vue'
export default {
  name: 'form-meli',
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    TextSuccessIntegrationMeli
  },
  data () {
    return {
      userCompany: '',
      userName: '',
      userLastName: '',
      userEmail: '',
      required,
      email,
      loading: false,
      title: '¡Integración exitosa!',
      initialForm: true,
      successIntegration: false,
      currentEmail: '',
      existingEmail: false,
      baseService: new BaseServices(this),
      decodedState: null
    }
  },
  computed: {
    isDisabled () {
      return this.loading || this.existingEmail
    },
    isIntegration () {
      return !!this.successIntegration
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.decodedState = this.$route.query?.state ? JSON.parse(atob(this.$route.query?.state)) : null
      if (this.decodedState) {
        this.userCompany = this.decodedState.company_name || null
        this.userName = this.decodedState.first_name || null
        this.userLastName = this.decodedState.last_name || null
        this.userEmail = this.decodedState.email || null
      }
    },
    onChangeEmail () {
      if (!!this.currentEmail) {
        this.existingEmail = this.currentEmail === this.userEmail
      }
    },
    validateForm () {
      this.createUser()
    },
    createUser () {
      this.loading = true
      const payload = {
        first_name: this.userName,
        last_name: this.userLastName,
        email: this.userEmail,
        hash: this.decodedState.hash
      }
      const params = {}
      const config = {
        fullResponseError: true
      }
      this.baseService.callService('createUserMeli', payload, params, config)
        .then(resp => {
          this.initialForm = false
          this.title = '¡Datos confirmados con éxito!'
          this.successIntegration = true
        })
        .catch(err => {
          if (err?.data?.errors?.email && err.data.errors.email[0] === 'The email has already been taken.') {
            this.existingEmail = true
            this.currentEmail = this.userEmail
            this.userEmail = ''
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Oops',
                icon: 'WarningIcon',
                variant: 'danger',
                text: `Problemas al crear el usuario ${err.message}`
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style>
#container-form-meli {
    overflow: hidden;
    min-height: 100vh;
}
#container-form-meli p {
    color: #000;
}
.line-orientation {
    border: none; 
    border-left: 1px solid #091E3F; 
    height: 70px;
    margin: 1rem 1.5rem;
}
.meli-form label {
    display: flex;
    align-items: center;
}
.meli-form label strong {
    font-size: 14px;
}
.meli-form label span{
    font-size: 18px;
    padding: 0;
    margin: 0;
}
.meli-form .form-control {
    height: 3rem;
}
.meli-form .custom-btn-form-meli {
    border-color: #2F8BDC !important;
    background-color: #2F8BDC !important;
    color: #fff !important;
}
.meli-form .custom-btn-form-meli.disabled,
.meli-form .custom-btn-form-meli:disabled {
    background-color: #2f8bdc93 !important;
    border: none !important;
}
.wd-btn-meli {
    width: 50%;
}
.wd-form-meli {
    width: 60%;
}

.form-tooltip {
    display: none;
    box-sizing: border-box;
    padding: 4px 16px;
    position: absolute;
    left: 0;
    bottom: 100%;
    z-index: 3;
    background-color: #fff;
    border: 1px solid #E2E8F0;
    border-radius: 10px;
    box-shadow: 0px 0.5px 5px 0px rgb(0 0 0 / 18%);
    color: inherit;
    min-height: 48px;
}

.form-tooltip.visible {
    display: block;
    -webkit-animation: fold ease 500ms;
    animation: fold ease 500ms;
    -webkit-animation-direction: normal;
    animation-direction: normal;
}

.form-tooltip ul {
    padding: 0;
}

.form-tooltip ul li {
    list-style: none;
    font-size: 14px;
    margin-bottom: 0.5rem;
}

.form-tooltip ul li span {
    color: #868686;
}

.form-tooltip h3 {
    color: #6C6C6C;
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem 0;
    font-size: 15px;
    font-weight: 600;
}

.form-tooltip:before,
.form-tooltip:after {
    content: "";
    position: absolute;
    right: 50%;
    width: 0;
    height: 0;
    margin-right: -8px;
}

.form-tooltip:before {
    bottom: -13.5px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #E2E8F0;
}

.form-tooltip:after {
    bottom: -9px;
    border-left: 11px solid transparent;
    border-right: 12px solid transparent;
    border-top: 11px solid #fff;
}
@-webkit-keyframes fold {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    }
@keyframes fold {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}
.check-circle-confirm{
    color: #97BA25 !important;
}
@media (max-width: 870px) { 
    .container-img img {
        width: 120px;
    }
    .line-orientation {
        height: 50px;
    }
    .wd-form-meli, .wd-btn-meli {
        width: 100%;
    }
}
</style>