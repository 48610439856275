var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex p-4 flex-column align-items-center bg-white animated fadeInDown justify-content-start",attrs:{"id":"container-form-meli"}},[_vm._m(0),_c('div',{staticClass:"d-flex flex-column align-items-center mt-2"},[_c('text-success-integration-meli',{class:{'animated fadeInDown' : _vm.isIntegration},attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',[_c('p',{staticClass:"mt-50 text-center",class:{'mb-0' : _vm.isIntegration}},[(_vm.initialForm)?_c('span',[_vm._v("Ya estás a pocos pasos de tener todo listo para usar tu cuenta Envíame.")]):_vm._e(),(_vm.successIntegration)?_c('span',[_vm._v("Hemos enviado tus "),_c('strong',[_vm._v("credenciales de acceso temporales al correo")]),_vm._v(" del usuario que acabas de confirmar.")]):_vm._e()]),_c('p',{staticClass:"text-center",class:{'m-0' : _vm.isIntegration}},[(_vm.initialForm)?_c('span',[_vm._v("Confirma los siguientes datos y crea una contraseña segura. Para continuar selecciona el botón "),_c('strong',[_vm._v("“Confirmar”")]),_vm._v(".")]):_vm._e(),(_vm.successIntegration)?_c('span',[_vm._v("Por tu seguridad, recomendamos que cambies tu contraseña luego del primer acceso.")]):_vm._e()]),(_vm.isIntegration)?_c('p',{staticClass:"text-center m-0 mt-2"},[(_vm.successIntegration)?_c('span',[_vm._v("¡Te esperamos pronto en Envíame!")]):_vm._e()]):_vm._e()])]},proxy:true}])}),(_vm.initialForm)?_c('div',{staticClass:"wd-form-meli"},[_c('validation-observer',{ref:"meliForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"meli-form mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mt-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"company"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('Empresa'))+" ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"name":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","disabled":"","name":"meli-company","state":errors.length > 0 ? false:null,"placeholder":"Ingresa tu empresa"},model:{value:(_vm.userCompany),callback:function ($$v) {_vm.userCompany=$$v},expression:"userCompany"}}),_c('small',{staticClass:"text-danger d-flex justify-content-start ml-50"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"name"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('Nombre de usuario'))+" ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":_vm.loading,"name":"meli-name","state":errors.length > 0 ? false:null,"placeholder":"Ingresa tu nombre de usuario"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('small',{staticClass:"text-danger d-flex justify-content-start ml-50"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"name"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('Apellido de usuario'))+" ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","disabled":_vm.loading,"name":"meli-last-name","state":errors.length > 0 ? false:null,"placeholder":"Ingresa tu apellido de usuario"},model:{value:(_vm.userLastName),callback:function ($$v) {_vm.userLastName=$$v},expression:"userLastName"}}),_c('small',{staticClass:"text-danger d-flex justify-content-start ml-50"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"email"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('Usuario'))+" ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-valid': !_vm.existingEmail && !!_vm.currentEmail},attrs:{"id":"email","disabled":_vm.loading,"name":"meli-email","state":errors.length > 0 ? false:null,"placeholder":!!_vm.existingEmail ? ("Ingrese correo diferente a " + _vm.currentEmail) : 'Ingresa tu usuario'},on:{"keyup":_vm.onChangeEmail},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),(!!_vm.existingEmail)?_c('small',{staticClass:"text-danger d-flex justify-content-start ml-50"},[_vm._v("Ya existe una cuenta Envíame asociada al correo "+_vm._s(_vm.currentEmail))]):_c('small',{staticClass:"text-danger d-flex justify-content-start ml-50"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mt-4 custom-btn-form-meli wd-btn-meli",attrs:{"type":"submit","block":"","disabled":invalid || _vm.isDisabled}},[(_vm.loading)?_c('feather-icon',{staticClass:"spinner",attrs:{"icon":"LoaderIcon"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('Confirmar'))+" ")],1)],1)],1)]}}],null,false,604573146)})],1):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center container-img"},[_c('img',{attrs:{"src":"https://storage.googleapis.com/marketcenter-labels-local/logo_enviame.svg","width":"200","alt":""}}),_c('hr',{staticClass:"line-orientation"}),_c('img',{attrs:{"src":"https://storage.googleapis.com/marketcenter-labels/logo_meli.png","width":"200","alt":""}})])}]

export { render, staticRenderFns }